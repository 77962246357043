import React, {useState, useRef, useEffect} from "react";
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Link} from "react-router-dom";
import styled from "styled-components";
import leftUrl from "../assets/images/left.svg"
import organsAndSkeletonUrl from "../assets/images/organs-and-skeleton.svg"
import organsUrl from "../assets/images/organs.svg"
import rightUrl from "../assets/images/right.svg"
import skeletonDesktop from "../assets/images/skeletonMobileAndDesktop.svg"
import {Box} from "../components/Box";
import {Column, ColumnsContent, SideBarLayout} from "../components/layouts";
import Menu, {MenuButton} from "../components/Menu";

const SpecialColumn = styled(Column)`
  @media screen and (max-width: 768px) {
    margin: 5px 20px 0 20px;
  }
`

const CarouselWrapper = styled.div`
  @media screen and (min-width: 1201px) {
    width: 50%;
    margin: 0 auto;
  }
`

const Slide = styled.div`
  position: relative;
  margin-bottom : -30px;
  @media screen and (max-width: 640px){
    margin-bottom : -10px;
  }
  @media screen and (max-width: 400px){
    margin-bottom : -4px;
    }
`

const Hit = styled(Link)`
    
  display: block;
  position: absolute;
  background: transparent;
  color : ${p => p.color ? p.color : "#446486"};
  left: ${p => p.left + "%"};
  top: ${p => p.top + "%"};
  width: ${p => p.width + "%"};
  height: ${p => p.height ? p.height + "px" : "5%"};
`

const Navigation = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr min-content min-content min-content 1fr;
  grid-gap: 1em;
  align-items: center;

  margin-top: 1em;
  font-size: 1.3em;

  margin-bottom: 1em;

  @media screen and (max-width: 768px) {
    font-size: 1em;
    margin-top: -2em;
    margin-bottom: 0;
  }
`

const NavLink = styled.a`
  justify-self: center;
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;

  color: ${p => p.active ? "#284d71" : "#9cadbe"};

  &:first-child {
    justify-self: end;
  }

  &:last-child {
    justify-self: start;
  }
`

const Description = styled.p`
`

const Arrow = styled.img`
  cursor: pointer;
`

const Organs = () => {
    return (
        <>
            <Hit
                top={26}
                left={12.5}
                width={7.5}
                to="/parts/brust"
            />

            <Hit
                top={29}
                left={80}
                width={7.5}
                to="/parts/herz"
            />

            <Hit
                top={35}
                left={80}
                width={10}
                to="/parts/lunge"
            />

            <Hit
                top={49}
                left={80}
                width={17}
                to="/parts/bauchraum"
            />
        </>
    )
}

const Skeleton = () => {
    return (
        <>
            <Hit
                top={14}
                left={80}
                width={12}
                to="/parts/schadel"
            />


            <Hit
                top={33}
                left={5}
                width={15}
                to="/parts/brustkorb"
            />

            <Hit
                top={22}
                left={80}
                width={10}
                to="/parts/gebiss"
            />

            <Hit
                top={42}
                left={80}
                width={11}
                to="/parts/bechen"
            />

            <Hit
                top={42}
                left={1}
                width={28}
                to="/parts/lendenwirbelsaule"
            />

            <Hit
                top={86}
                left={14}
                width={6}
                to="/parts/fus"
            />

        </>
    )
}


const Skelett = () => {
    return (
        <>
            <Hit
                top={14}
                left={80}
                width={16}
                to="/parts/schadel"
            />


            <Hit
                top={33}
                left={5}
                width={19}
                to="/parts/brustkorb"
            />

            <Hit
                top={23}
                left={80}
                width={12}
                to="/parts/gebiss"
            />

            <Hit
                top={42}
                left={80}
                width={14}
                to="/parts/bechen"
            />

            <Hit
                top={42}
                left={1}
                width={29}
                to="/parts/lendenwirbelsaule"
            />

            <Hit
                top={86}
                left={14}
                width={8}
                to="/parts/fus"
            />

        </>
    )
}


let lastSlide = 1;

const useSlideState = () => {
    const [slide, _setSlide] = useState(lastSlide);

    const setSlide = (s) => {
        lastSlide = s;
        _setSlide(s);
    }

    return {
        slide,
        setSlide
    }
}

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {slide, setSlide} = useSlideState();

    const gotoHandler = (i) => {
        return (e) => {
            e.preventDefault();
            setSlide(i);
        }
    }

    return (
        <SideBarLayout>
            <Menu/>

            <ColumnsContent>
                <Column>
                    <Box>
                        <MenuButton/>

                        <h1>Auswahl</h1>
                        <Description>
                            Wählen Sie durch einen Klick auf den entsprechenden Begriff neben der Grafik aus,
                            zu welcher Röntgenuntersuchung Sie weitere Informationen möchten.
                            <br/>
                        </Description>
                    </Box>

                </Column>
                <SpecialColumn>
                    <CarouselWrapper>
                        <Carousel
                            preventMovementUntilSwipeScrollTolerance
                            swipeScrollTolerance={100}
                            infiniteLoop
                            showArrows={false}
                            showStatus={false}
                            showIndicators={false}
                            showThumbs={false}
                            selectedItem={slide}
                            onChange={setSlide}
                        >
                            <Slide>
                                <img src={skeletonDesktop} alt="" />
                                <Skelett/>
                            </Slide>

                            <Slide>
                                <img src={organsAndSkeletonUrl} alt=""/>
                                <Organs/>
                                <Skeleton/>
                            </Slide>

                            <Slide>
                                <img src={organsUrl} alt=""/>
                                <Organs/>
                            </Slide>
                        </Carousel>
                    </CarouselWrapper>

                    <Navigation>
                        <NavLink
                            href="#"
                            active={slide === 0}
                            onClick={gotoHandler(0)}
                        >Skelett</NavLink>

                        <Arrow src={leftUrl} alt="" onClick={gotoHandler(0)}/>

                        <NavLink
                            href="#"
                            active={slide === 1}
                            onClick={gotoHandler(1)}
                        >Skelett und Organe</NavLink>

                        <Arrow src={rightUrl} alt="" onClick={gotoHandler(2)}/>

                        <NavLink
                            href="#"
                            active={slide === 2}
                            onClick={gotoHandler(2)}
                        >Organe</NavLink>
                    </Navigation>
                </SpecialColumn>

            </ColumnsContent>
        </SideBarLayout>
    );
};

export default Home;
