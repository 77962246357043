import React from "react";
import styled from "styled-components"

import timesUrl from "../assets/images/x-mark.svg";
import {Button, WhiteButton} from "./Buttons";

const Shadow = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: ${p => p.show ? "flex" : "none"};
  background: rgba(136, 184, 207, 0.34);
  justify-content: center;
  align-items: center;
`

const Box = styled.div`
  position: relative;
  max-width: 400px;
  padding: 30px;
  margin: 0 20px;
  text-align: center;
  background-color: white;
`

const Popup = (props) => {
    const {
        show,
        onClose,
        children,
    } = props;


    return (
        <Shadow show={show} onClick={onClose}>
            <Box onClick={e => e.stopPropagation()}>
                {children}
            </Box>
        </Shadow>
    )
}

export default Popup;

export const CloseButton = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 1.4em;
  cursor: pointer;
`

export const Times = (props) => {
    return (
        <CloseButton
            src={timesUrl}
            onClick={props.onClick}
        />
    )
}

export const Heading = styled.h3`
  color: #284d71;
`

export const Body = styled.p`
  color: #284d71;
  line-height: 1.8em;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`

export const DataDeleteConfirmationPopup = (props) => {
    const {
        show,
        onClose,
        onConfirm,
    } = props;

    return (
        <Popup show={show} onClose={onClose}>
            <Times onClick={onClose}/>

            <Heading>Browserdaten löschen</Heading>

            <Body>
                Sind Sie sicher, dass Sie alle Daten dieser Anwendung in Ihrem Browser löschen möchten?
            </Body>

            <Buttons>
                <Button onClick={onConfirm}>Ja, löschen</Button>
                <WhiteButton onClick={onClose}>nein, Daten behalten</WhiteButton>
            </Buttons>
        </Popup>
    )

}
