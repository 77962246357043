import schadelUrl from "./assets/images/parts/schadel.svg"
import bauchraumUrl from "./assets/images/parts/bauchraum.jpg"
import brustkorbUrl from "./assets/images/parts/brustkorb.jpg"
import gebissUrl from "./assets/images/parts/gebiss.jpg"
import lungeUrl from "./assets/images/parts/lunge.png"
import brustUrl from "./assets/images/parts/brust.jpg"
import herzUrl from "./assets/images/parts/herz.jpg"
import bechenUrl from "./assets/images/parts/bechen.svg"
import lendenwirbelsauleUrl from "./assets/images/parts/lendenwirbersaule.png"
import fusUrl from "./assets/images/parts/fus.jpg"

export default {
    schadel: {
        img: schadelUrl,
        title: "Computertomographie des Kopfes",
        caption: "Das CT-Bild zeigt eine Querschnittsaufnahme des Schädels eines Patienten mit einer Hirnblutung. " +
            "Durch die Blutung (weiß) kommt es zu einer Verschiebung der Mittellinie (Pfeil), " +
            "die bei gesunden Menschen als gerade Linie die beiden Gehirnhälften voneinander trennt.",
        text: "Eine <b>Computertomographie des Kopfes</b> wird zum Beispiel nach Schädel-Hirn-Verletzung bevorzugt " +
            "eingesetzt, da man auf den Querschnittsbildern mögliche Verletzungen des Hirngewebes gut erkennen kann.",
        dose: "Typische effektive Dosis bei einer Computertomographie Kopf: 1-2 Millisievert (mSv)."
    },

    bauchraum: {
        img: bauchraumUrl,
        title: "CT-Untersuchungen des Bauchraums (Abdomen)",
        caption: "Das CT-Bild zeigt eine Querschnittsaufnahme des Bauchraums mit Lebermetastasen. " +
            "Die Leber ist hellgrau auf der linken Bildseite zu sehen. Die Lebermetastase zeigt sich als " +
            "dunkelgraue rundliche Struktur (Pfeil). Weiß erkennbar sind der Wirbel und die Knochen des Brustkorbs.",
        text: "Eine <b>Computertomographie des Bauchraums</b> liefert Bilder und wichtige Informationen zu möglichen " +
            "Krankheiten der inneren Organe:" +
            "<ul>" +
            "<li>Milz</li>" +
            "<li>Leber</li>" +
            "<li>Magen-Darm</li>" +
            "<li>Nieren und Blase</li>" +
            "<li>Bauchspeicheldrüse</li>" +
            "<li>Galle</li>" +
            "<li>Blutgefäße</li>" +
            "</ul>",
        dose: "Typische effektive Dosis bei einer Computertomographie Bauchraum: 7 - 12 Millisievert (mSv).",
    },

    brustkorb: {
        img: brustkorbUrl,
        title: "CT Brustkorb (Thorax)",
        caption: "Das CT-Bild zeigt eine Querschnittsaufnahme des Brustkorbs und der Lungen mit einer Raumforderung (Pfeil).",
        text: "Eine <b>Computertomographie des Brustkorbs (Thorax)</b> wird unter anderem eingesetzt, um Erkrankungen " +
            "der Atemwege zu erkennen, etwa chronische, entzündliche Lungen- und Bronchialerkrankungen, aber auch, um " +
            "akute Beschwerden abzuklären. Der Pfeil im gezeigten Beispiel zeigt auf eine Raumforderung (Tumor).",
        dose: "Typische effektive Dosis bei einem CT Brustkorb: 4-7 Millisievert (mSv).",
    },

    gebiss: {
        img: gebissUrl,
        title: "OPT Milchgebiss",
        caption:"Panoramaaufnahme (Orthopantomogramm), die das Milchgebiss eines Kindes mit Milchzähen" +
            "(kleiner Pfeil) und bleibenden Zähnen (großer Pfeil) zeigt.",
        text: "Eine <b>Zahnübersichtsaufnahme (Panoramaaufnahme oder Orthopantomogramm (OPT))</b>, aber auch " +
            "Einzelaufnahmen von Zähnen werden in der Zahnmedizin angefertigt, um die Entwicklung, Anlage oder " +
            "Stellung von Zähnen bei Kindern und Erwachsenen zu beurteilen oder Erkrankungen an Zähnen sowie an der " +
            "Verankerung der Zähne im Kiefer (Zahnhalteapparat) zu erkennen. Zahnaufnahmen sind die häufigste " +
            "Röntgenuntersuchungsart in Deutschland.",
        dose: "Typische effektive Dosis bei einer Zahnaufnahme: 0,003 - 0,03 Millisievert (mSv).",
    },

    lunge: {
        img: lungeUrl,
        title: "Röntgenübersichtsaufnahme der Lunge (Röntgen-Thorax)",
        caption: "",
        text: "Eine <b> Röntgenaufnahme des Brustkorbs (Thorax) </b> zeigt nicht nur den Brustkorb mit Brustwirbelsäule und Rippen (Pfeil links)," +
            " sondern auch die dort liegenden Organe und Strukturen wie etwa Herz (Pfeil rechts oben) und Lungen oder das Zwerchfell (Pfeil rechts unten)." +
            " Die Aufnahmen werden unter anderem genutzt, um Erkrankungen der Lunge (zum Beispiel Lungenentzündungen) sowie des Herzens genauer zu erkennen.",
        dose: "Typische effektive Dosis bei einer frontalen Röntgenaufnahme des Thorax in einer Ebene: 0,01 – 0,03 Millisievert (mSv).",
    },

    brust: {
        img: brustUrl,
        title: "Röntgenaufnahme der Brust (Mammographie)",
        caption: "Mammographie einer Patientin mit einem tumorverdächtigen Befund (Pfeil).",
        text: "Eine <b>Mammographie</b> ist eine Röntgenaufnahme der Brust unter anderem zur Erkennung von Brustkrebs. " +
            "In Deutschland wird sie für Frauen zwischen 50 und 69 Jahren auch zur Früherkennung von Brustkrebs im " +
            "Rahmen des Mammographie-Screening-Programms eingesetzt.",
        dose: "Typische effektive Dosis bei einer Mammographie beidseits in je 2 Ebenen: 0,2 – 0,4 Millisievert (mSv).",
    },

    herz: {
        img: herzUrl,
        title: "Gefäßdarstellung der Herzkranzgefäße (Koronarangiographie)",
        caption: "Auf dem Bild sind Herzkranzgefäße (Dunkelgrau/ Schwarz) mit Engstellen (Pfeil) zu sehen",
        text: "Eine <b>Koronarangiographie</b> wird eingesetzt, um krankhafte Veränderungen der herzversorgenden " +
            "Arterien zu diagnostizieren. Dafür" + " werden Kontrastmittel injiziert und anschließend die Verteilung des Kontrastmittels in den Gefäßen aufgenommen.",
        dose: "Typische effektive Dosis bei einer Koronarangiographie: 3 - 8 Millisievert (mSv).",
    },

    bechen: {
        img: bechenUrl,
        title: "Computertomographie (CT) Becken",
        caption: "Das CT-Bild zeigt eine Querschnittsaufnahme des Beckens (weiß) mit mehreren Knochenbrüchen (Pfeile).",
        text: "Eine <b>Computertomographie des Beckens</b> liefert wichtige Informationen bezüglich der vom Beckenknochen umschlossenen inneren Organe sowie des Steißbeins und der Lendenwirbelsäule.",
        dose: "Typische effektive Dosis bei einer Computertomographie des Beckens: 3 - 6 Millisievert (mSv).",

    },

    lendenwirbelsaule: {
        img: lendenwirbelsauleUrl,
        title: "Röntgen Lendenwirbelsäule",
        caption: "Das Röntgenbild zeigt Verschleißerscheinungen der Lendenwirbelsäule mit knöchernen Anbauten an den " +
            "Wirbelgelenken und den Wirbelkörpern (kurzer Pfeil). Die Bandscheibenräume sind verschmälert (langer Pfeil)",
        text: "Eine <b>Röntgenaufnahme der Lendenwirbelsäule</b> dient der Abklärung von gesundheitlichen Problemen in diesem Bereich des Bewegungsapparates. " +
            "In der Regel werden zwei Aufnahmen gemacht: einmal von vorne und einmal von der Seite.",
        dose: "Typische effektive Dosis bei einer Röntgenaufnahme der Lendenwirbelsäule in zwei Ebenen: 0,3 – 0,8 " +
            "Millisievert (mSv).",
    },

    fus: {
        img: fusUrl,
        title: "Röntgen Fuß",
        caption: "Das Röntgenbild zeigt einen linken Fuß mit zwei Brüchen (Pfeile) im Bereich des Mittelfußes.",
        text: "<b>Röntgenaufnahmen der unteren Extremitäten</b> dienen der Abklärung von gesundheitlichen Problemen " +
            "in diesem Bereich des Bewegungsapparates. Zu den unteren Extremitäten zählen die Ober- und Unterschenkel, " +
            "die Kniegelenke und die Füße.",
        dose: "Typische effektive Dosis bei einer Röntgenaufnahme der unteren Extremitäten: 0,01 – 0,1 Millisievert (mSv)"

    }
}
