import styled from "styled-components";

export const SideBarLayout = styled.div`
  @media screen and (min-width: 769px) {
    display: grid;
    grid-template-columns: 250px auto;
    min-height: 100vh;
  }

  @media screen and (max-width: 768px) {
  }
`;

export const CenteredContent = styled.div`
  max-width: 100%;
  margin: 20px auto;
  padding: 0 20px;
  
  @media screen and (max-width: 1200px) {
    width: calc(100% - 40px);
  }
`

export const ColumnsContent = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1201px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 1200px) {
    grid-template-rows: auto auto;

    & > *:first-child {
      grid-row: 2;
    }

    & > *:last-child {
      grid-row: 1;
    }
  }
`;

export const Column = styled.div`
  margin: 20px 20px 0 20px;
`;

export const SingleColumnLayout = styled.div`
  margin: ${p => p.margin ? p.margin : '20px 20px 0 20px'};
`
