import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components"
import Box from "../components/Box";
import {Column, ColumnsContent, SideBarLayout,} from "../components/layouts";
import Menu, {MenuButton} from "../components/Menu";
import {getElement} from "bootstrap/js/src/util";

const Header = styled.div`
  color: black;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;

  div:first-child {
    font-size: 1.3em;
    font-weight: 600;
  }
  
  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr 0;
    div:last-child {
      display: none;
    }
  }
`

const Row = styled.div`
  margin: 6px 0;
  border: 1px solid #bbbbbb;
  border-left: none;
  border-right: none;

  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: stretch;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
  }
`

const Label = styled.div`
  padding: 3px 6px;
  background: #e3f0f5;
  color: black;
`

const DoseWrapper = styled.div`
    position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const DoseBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #284d71;
  color: white;
  width: ${p => p.width+'%'};
  border-left: 1px solid #284d71;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
`

const DoseLabelBack = styled.b`
  position: relative;
  color: white;
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 0.9em;
  transform: translateY(1px);
`

const DoseLabelFront = styled.b`
  position: relative;
  color: black;
  margin-left: 10px;
  font-size: 0.9em;
  transform: translateY(1px);
`

const HighlightedText = styled.p`
    color : #5b8445;
    font-size : 1.3em;
    font-weight: bold;
    line-height: 1.13;
`

const HighlightedNumber = styled.p`
    color : #5b8445;
    font-size : 1.3em;
    font-weight: bold;
    line-height: 1.13;
    text-align: center;
    margin: 20px 5px  0 15px;
`

const norm = Math.log

const Bar = styled.div`
    width: ${ p => 100 - p.width + '%' };
    background: transparent;
`

const  FilledBar = styled.div`
  width: ${ p => p.width + '%' };
  background: #5b8445;
`

const Flex = styled.div`
    width: 100%;
    display: flex;
`

const GreenText = styled.div`
  color : #5b8445;
  font-weight: 500;
  font-size: 19px;
  margin-top: 20px;
`

const DynamicBar = ({percent}) => {

    return(
        <Flex>
            <Bar width={ percent }></Bar>
            <FilledBar width={ percent }/>
        </Flex>
    )
}

const Dose = ({amount}) => {
    const amountStr = amount.toString().replace(".", ",")
    const width = amount * 10;
    return (
        <DoseWrapper>
            <DoseLabelFront>{amountStr}</DoseLabelFront>
            <DoseBar width={width}>
                {amount < 10 ?
                    <DoseLabelBack/> :
                    <DoseLabelBack>{amountStr}</DoseLabelBack>
                }
            </DoseBar>
        </DoseWrapper>
    )
}

const Table = () => {
    return (
        <div>
            <Header>
                <div>... von Röntgenaufnahmen</div>
                <div>Millisievert (mSv)</div>
            </Header>

            <Row>
                <Label>Zahnaufnahme</Label>
                <Dose amount={0.01}/>
            </Row>
            <Row>
                <Label>Untere Extremitäten (Gliedmaßen)</Label>
                <Dose amount={0.01}/>
            </Row>
            <Row>
                <Label>Schädelaufnahme</Label>
                <Dose amount={0.02}/>
            </Row>
            <Row>
                <Label>Halswirbelsäule in 2 Ebenen</Label>
                <Dose amount={0.2}/>
            </Row>
            <Row>
                <Label>Brustkorb (Thorax) in einer Ebene</Label>
                <Dose amount={0.04}/>
            </Row>
            <Row>
                <Label>Mammografie beidseits in je 2 Ebenen</Label>
                <Dose amount={0.4}/>
            </Row>
            <Row>
                <Label>Brustwirbelsäule in 2 Ebenen</Label>
                <Dose amount={0.3}/>
            </Row>
            <Row>
                <Label>Lendenwirbelsäule in 2 Ebenen</Label>
                <Dose amount={0.6}/>
            </Row>
            <Row>
                <Label>Beckenübersicht</Label>
                <Dose amount={0.3}/>
            </Row>
            <Row>
                <Label>des Bauchraums (Abdomenübersicht)</Label>
                <Dose amount={0.4}/>
            </Row>

            <Header>
                <div>... von Röntgendurchleuchtungen</div>
                <div>Millisievert (mSv)</div>
            </Header>

            <Row>
                <Label>Darm (Dünndarm bzw. Kolonkontrasteinlauf)</Label>
                <Dose amount={5}/>
            </Row>
            <Row>
                <Label>Koronarangiografie</Label>
                <Dose amount={5}/>
            </Row>
            <Row>
                <Label>PTCA (perkutane transluminale coronare Angioplastie zur Herzkranzgefäßerweiterung)</Label>
                <Dose amount={9}/>
            </Row>
            <Row>
                <Label>Bein-Becken-Phlebografie (ein Bein)</Label>
                <Dose amount={0.4}/>
            </Row>
            <Row>
                <Label>Becken-Bein-Arteriografie</Label>
                <Dose amount={5}/>
            </Row>

            <Header>
                <div>... von CT-Untersuchungen</div>
                <div>Millisievert (mSv)</div>
            </Header>

            <Row>
                <Label>Computertomographie des Kopfes</Label>
                <Dose amount={2}/>
            </Row>
            <Row>
                <Label>Lendenwirbelsäule (Bandscheibe)</Label>
                <Dose amount={2}/>
            </Row>
            <Row>
                <Label>Brustkorb (Thorax)</Label>
                <Dose amount={5}/>
            </Row>
            <Row>
                <Label>des Bauchraums (Abdomen)</Label>
                <Dose amount={10}/>
            </Row>

            <Header>
                <div>... von nuklearmedizinischen Verfahren</div>
                <div>Millisievert (mSv)</div>
            </Header>

            <Row>
                <Label>Schilddrüsenszintigrafie</Label>
                <Dose amount={0.9}/>
            </Row>
            <Row>
                <Label>Skelettszintigrafie</Label>
                <Dose amount={2.8}/>
            </Row>


        </div>
    )
}

const DoseTable = () => {

    const [statBar, setStatBar] = useState();
    const ref = useRef();

    useEffect(() => {
       let barWidth = Number(ref.current.innerText.toString().replace(",", ".")) * 10;

       setStatBar(barWidth)
    },[ref]);

    return (
        <SideBarLayout>
            <Menu/>
            <ColumnsContent>
                <Column>
                    <Box>
                        <MenuButton/>

                        <h1>Typische effektive Dosis</h1>

                        <p>
                            Die folgende Grafik gibt für häufige Röntgenuntersuchungen typische Werte für die effektive Dosis in Millisievert (mSv) an.
                            Zum Vergleich in grün: die effektive Dosis des Menschen durch natürliche Quellen. Sie beträgt im Mittel etwa
                        </p>
                        <Flex>
                            <DynamicBar percent={ statBar }/>
                            <HighlightedNumber ref={ref}>2,1</HighlightedNumber>
                            <GreenText>mSv</GreenText>
                        </Flex>
                        <HighlightedText>... aus natürlichen Strahlungsquellen pro Jahr</HighlightedText>
                    </Box>
                </Column>
                <Column>
                    <Table/>
                </Column>
            </ColumnsContent>
        </SideBarLayout>
    );
};

export default DoseTable;
