import {useEffect, useState} from "react";

const HISTORY_KEY = "x-ray-history";
export const HISTORY_TYPES = [
    "Röntgen Kopf",
    "CT Kopf",
    "Zahnaufnahme",
    "Röntgen und CT Thorax",
    "Mammographie",
    "Arterien: Koronarangiographie",
    "CT Bauchraum",
    "Magen und Darm",
    "Becken",
    "Lendenwirbelsäule",
    "Gliedmaßen",
];


export const useLocalXRayHistory = () => {
    const [history, setHistory] = useState([]);

    useEffect(() => {
        const raw = localStorage.getItem(HISTORY_KEY);
        if (!raw) {
            setHistory([
                // {date: new Date("2021-08-01"), type: HISTORY_TYPES[0], dose: "0.012"},
                // {date: new Date("2021-08-02"), type: HISTORY_TYPES[1], dose: "0.003"},
            ]);
            return;
        }

        setHistory(JSON.parse(raw));
    }, []);

    const update = (data) => {
        setHistory(data);
        localStorage.setItem(HISTORY_KEY, JSON.stringify(data));
    };

    return {
        history,
        updateHistory: update,
    };
};

export const clearHistory = () => {
    localStorage.removeItem(HISTORY_KEY);
}
