import React, {useState,useEffect} from "react";
import "react-calendar/dist/Calendar.css";
import {Box} from "../components/Box";
import {atom,useRecoilState} from "recoil";
import {clearHistory} from "../lib/history";
import {useHistory} from "react-router-dom";
import {SideBarLayout, SingleColumnLayout} from "../components/layouts";
import Menu, {MenuButton} from "../components/Menu";
import {PrivacyButton} from "../components/Buttons";
import {DataDeleteConfirmationPopup} from "../components/Popup";
import styled from "styled-components";

const menuState = atom({
    key: "menu",
    default: false,
})

const NavLink = styled.a`
  justify-self: center;
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;
  color: blue;
  cursor: pointer;
`
const People = styled.p`
  margin: 3px 0 0 0;
  padding: 3px 0 0 0;
`

const Acknowledgments = () => {

    return (
        <SideBarLayout>
            <Menu/>

            <SingleColumnLayout>
                <Box>
                    <MenuButton/>

                    <h1>Bildquellen & Danksagung</h1>
                    <p>Alle Bilder wurden freundlicherweise von der Abteilung für diagnostische und interventionelle Radiologie am Universitätsklinikum Tübingen zu Verfügung gestellt.</p>
                    <People>Dr. Saif Afat </People>
                    <People> Dr. Andreas Brendlin</People>
                    <People>PD Dr. Johann-Martin Hempel (Neuroradiologie)</People>
                    <People> Cecilia Zhang</People>
                    <People>Dr. Sebastian Gassenmaier</People>
                    <People>Markus Mader</People>
                    <People>Dr. Haidara Al Mansour</People>
                    <People>Prof. Dr. Konstantin Nikolaou</People>
                </Box>
            </SingleColumnLayout>
        </SideBarLayout>

    )
};

export default Acknowledgments;
