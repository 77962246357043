import React from "react";
import {useHistory} from "react-router-dom";
import styled from "styled-components";

export const Button = styled.button`
  background-color: #95ba83;
  text-transform: uppercase;
  border: 1px solid #95ba83;
  font-family: "Calibri-Regular", sans-serif;
  line-height: 3em;
  padding: 0 3em;
  text-align: center;
  color: black;

  &:active {
    transform: translateY(1px);
  }
`;

export const WhiteButton = styled(Button)`
  background-color: white;
  color: #95ba83;
  font-family: "Calibri-Regular", sans-serif;
  display: flex;
  margin-right : 32px;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:active {
    transform: translateY(1px);
  }

  & > img {
    margin-right: 0.4em;
    height: 1.4em;
  }
`;

export const IconButton = styled(Button)`
  padding: 0;
  display: inline-flex;
  //grid-template-columns: min-content auto;
  //justify-items: stretch;
  //align-items: stretch;
  text-align: center;
  outline: none;
  cursor: pointer;
  //width: 200px;

  & > i {
    display: inline-block;

    font-style: normal;
    transform: scale(0.6, 1.3) translateY(-1px);
    flex-basis: min-content;
    
    &:first-child {
      border-right: 1px solid white;
      padding: 0 2em 0 1em;
    }
    
    &:last-child {
      border-left: 1px solid white;
      padding: 0 1em 0 2em;
    }
  }

  & > span {
    flex-basis: 100%;
    display: inline-block;
    padding: 0 3em;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const BodyPartIconButton = styled(IconButton)`
  display: ${(p) => (!p.isMobile ? "inline-grid" : "none")};

  @media screen and (max-width: 1200px) {
    display: ${(p) => (p.isMobile ? "grid" : "none")};
  }
`;

export const BackButton = ({className}) => {
    const history = useHistory();

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <IconButton className={className} onClick={handleGoBack}>
            <i>&lt;</i>
            <span>zurück</span>
        </IconButton>
    );
};


export const ForwardButton = ({className, onClick}) => {
    return (
        <IconButton className={className} onClick={onClick}>
            <span>Weiter</span>
            <i>&gt;</i>
        </IconButton>
    );
};

export const PrivacyButton = styled.button`
  font-family: "Calibri-Regular", sans-serif;
  background-color : #95ba83;
    text-align: center;
    color : #000000;
    text-transform: uppercase;
    padding : 10px 18px;
    font-size:17px;
    font-style: normal;
    border-color : #95ba83;
    border-style: solid;
    @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
