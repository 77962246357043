import React, {useState,useEffect} from "react";
import "react-calendar/dist/Calendar.css";
import {Box} from "../components/Box";
import {atom,useRecoilState} from "recoil";
import {clearHistory} from "../lib/history";
import {useHistory} from "react-router-dom";
import {SideBarLayout, SingleColumnLayout} from "../components/layouts";
import Menu, {MenuButton} from "../components/Menu";
import {PrivacyButton} from "../components/Buttons";
import {DataDeleteConfirmationPopup} from "../components/Popup";
import styled from "styled-components";

const menuState = atom({
    key: "menu",
    default: false,
})

const NavLink = styled.a`
  justify-self: center;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  color: black;
  cursor: pointer;
`



const Privacy = () => {
    const [open, setOpen] = useRecoilState(menuState);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const history = useHistory();
    const handleShowDeleteConfirm = (e) => {
        e.preventDefault();
        setShowDeleteConfirm(true);
    }

    const handleDataDeleteConfirm = () => {
        setShowDeleteConfirm(false);
        clearHistory();
        window.location.reload();
        setOpen(false);
    }

    useEffect(() => {
        setOpen(false);
    }, [history.location?.pathname])


    return (
        <SideBarLayout>
            <Menu/>

            <SingleColumnLayout>
                <Box>
                    <MenuButton/>

                    <h1>Datenschutz</h1>
                    <p>
                        Die von Ihnen eingegebenen Daten im <NavLink href={'/x-ray-history'}>Röntgenpass</NavLink>  werden vom Bundesamt für Strahlenschutz nicht verarbeitet, sondern werden ausschließlich auf Ihrem Gerät abgelegt.
                        Möchten Sie Ihre Informationen auch dort wieder löschen, klicken Sie bitte auf „DATEN LÖSCHEN".
                    </p>
                    <p>
                        Hinweis: Alle von Ihnen hinterlegten Informationen sind dann endgültig gelöscht und nicht mehr digital verfügbar. Sie können Ihre Daten deshalb zusätzlich auch als <NavLink href={'/x-ray-history'}>PDF-Dokument speichern oder drucken.</NavLink>
                    </p>
                </Box>
                <DataDeleteConfirmationPopup
                    show={showDeleteConfirm}
                    onClose={setShowDeleteConfirm.bind(null, false)}
                    onConfirm={handleDataDeleteConfirm}
                />

                <PrivacyButton onClick={handleShowDeleteConfirm}>Daten löschen</PrivacyButton>
            </SingleColumnLayout>
        </SideBarLayout>

    )
};

export default Privacy;
