import styled from "styled-components";
import logo from "../assets/images/logo.svg"
import home from "../assets/images/home.svg"
const Nav = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding : 0 24px 12px 24px;
    background-color : #ffffff;
    height : 70px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    @media screen and (max-width: 600px) {
    padding : 0 16px;
    }
 `;

const Logo = styled.img`
   width: 65%;
    @media screen and (max-width: 1125px) {
    width: 70%;
    }
`;

const RightSide = styled.div`
    display:flex;
    color : #757575;
    line-height: 1.33;
    font-size: 18px;
`

const RightSideText = styled.div`
      display: block;
      font-size: 18px;
      margin-right : 16px;
      line-height: 1.33;
      color: #757575;
      font-family: "Calibri-Regular", sans-serif;

  @media screen and (max-width: 768px) {
        display: none;
      }
`

const Link = styled.a`
    
`

const TopBar = () => {
    return (
        <Nav>
            <Link href="https://www.bfs.de/DE/home/home_node.html">
                <Logo src={logo} />
            </Link>
            <RightSide>
                <RightSideText>Zurück zur Übersicht</RightSideText>
                <Link href='https://www.bfs.de/DE/mediathek/multimedia/infografik/infografik_node.html'>  <img src={home} /> </Link>
            </RightSide>
        </Nav>
    )
}

export default TopBar;