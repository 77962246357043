import React, {useState, useEffect} from "react";
import styled from "styled-components";

const DropdownLabel = styled.span`
  cursor: pointer;
  color: #605f5f;
  display: block;
`

const DropdownContent = styled.div`
  position: absolute;
  margin-top: 5px;
  z-index: 10;
  left: -5px;
  width: min-content;
  display: ${p => p.open ? "block" : "none"};
  background: white;
`

const DropdownWrapper = styled.div`
  position: relative;
  outline: none;
  &:focus, &:active {
    outline: none;
  }
`


export const Dropdown = ({text, value, children}) => {
    const [isOpen, setIsOpen] = useState(false);

    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);

    useEffect(() => {
        close();
    }, [value, ])

    return (
        <DropdownWrapper
            tabIndex={0}
            onBlur={close}
        >
            <DropdownLabel onClick={open}>{text}</DropdownLabel>
            <DropdownContent
                open={isOpen}
                onMouseDown={e => e.preventDefault()}
            >{children}</DropdownContent>
        </DropdownWrapper>
    )
}
