import {BrowserRouter as Router, Redirect, Route, Switch,} from "react-router-dom";
import {RecoilRoot} from "recoil";
import "./App.css";
import DoseTable from "./pages/DoseTable";
import Home from "./pages/Home";
import Part from "./pages/Part";
import Privacy from "./pages/Privacy";
import Start from "./pages/Start";
import Tips from "./pages/Tips";
import XRayHistory from "./pages/XRayHistory";
import Acknowledgments from "./pages/Acknowledgments";

function App() {
    return (
        <RecoilRoot>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/start"/>
                    </Route>
                    <Route exact path="/start" component={Start}/>
                    <Route exact path="/home" component={Home}/>
                    <Route exact path="/tipps" component={Tips}/>
                    <Route exact path="/privacy" component={Privacy}/>
                    <Route exact path="/Acknowledgments" component={Acknowledgments}/>
                    <Route exact path="/x-ray-history" component={XRayHistory}/>
                    <Route exact path="/dose-table" component={DoseTable}/>
                    <Route exact path="/parts/:part" component={Part}/>
                </Switch>
            </Router>
        </RecoilRoot>
    );

}

export default App;
