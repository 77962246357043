import React, {useState, useEffect} from "react";
import {NavLink} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {atom, useRecoilState} from "recoil";
import styled from "styled-components";
import closeImage from "../assets/images/close.svg";
import menuIcon from "../assets/images/menu.svg";
import {clearHistory} from "../lib/history";
import {DataDeleteConfirmationPopup} from "./Popup";


const menuState = atom({
    key: "menu",
    default: false,
})

const Close = styled.a`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const Nav = styled.nav`
  background-color: #f8f8f8;
  font-family: "Calibri-Regular", sans-serif;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      border: solid 1px #dedede;

      a {
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: #284d71;
        text-decoration: none;
        padding: 20px 0 19px 20px;
      }

      a.active {
        border-left: 5px solid #95ba83;
        padding: 20px 0 19px 15px;
        color: black;
      }
    }
  }

  ${Close} {
    display: none;
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    z-index: 3;
    left: 20px;
    top: 20px;
    right: 20px;
    bottom: 20px;
    display: ${(p) => (p.show ? "block" : "none")};

    ${Close} {
      display: flex;
    }
  }

  @media print {
    display: none;
  }
`;

const Menu = () => {
    const [open, setOpen] = useRecoilState(menuState);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const history = useHistory();

    const handleShowDeleteConfirm = (e) => {
        e.preventDefault();
        setShowDeleteConfirm(true);
    }

    const handleDataDeleteConfirm = () => {
        setShowDeleteConfirm(false);
        clearHistory();
        window.location.reload();
        setOpen(false);
    }

    useEffect(() => {
        setOpen(false);
    }, [history.location?.pathname])

    return (
        <>
            <Nav show={open}>
                <ul>
                    <Close>
                        <img onClick={setOpen.bind(null, false)} src={closeImage} alt=""/>
                    </Close>
                    <ul>
                        <li>
                            <NavLink to="/home">Grafik zu Strahlenanwendungen</NavLink>
                        </li>
                        <li>
                            <NavLink to="/x-ray-history">Röntgenpass</NavLink>
                        </li>
                        <li>
                            <NavLink to="/tipps">Tipps Strahlenexposition</NavLink>
                        </li>
                        <li>
                            <NavLink to="/dose-table">Strahlendosen in der Medizin</NavLink>
                        </li>
                        <li>
                            <NavLink to="/privacy">Datenschutz</NavLink>
                        </li>
                    </ul>
                </ul>
            </Nav>

            <DataDeleteConfirmationPopup
                show={showDeleteConfirm}
                onClose={setShowDeleteConfirm.bind(null, false)}
                onConfirm={handleDataDeleteConfirm}
            />
        </>
    );
};

const MenuIcon = styled.img`
  position: absolute;
  top: 0;
  right: 0;

  display: none;
  cursor: pointer;
  
  @media screen and (max-width: 768px) {
    display: block;
  }
`

export const MenuButton = () => {
    const [_, setOpen] = useRecoilState(menuState);

    return (
        <MenuIcon alt="" src={menuIcon} onClick={setOpen.bind(null, true)}/>
    )
}

export default Menu;
