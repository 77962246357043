import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import styled from "styled-components";
import bodyPartsDefs from "../bodyPartsDefs"
import {BackButton} from "../components/Buttons";
import DefaultBox from "../components/Box";
import {Column, ColumnsContent, ColumnsLayoutHead, SideBarLayout,} from "../components/layouts";
import Menu, {MenuButton} from "../components/Menu";
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');

const DesktopImage = styled(Column)`
  @media screen and (max-width: 1200px) {
    display: none;
  }

  img {
    display: block;
    max-height: 60vh;
    margin: 0 auto;
    max-width: 100%;
  }
  
  p {
    font-style: italic;
    margin-left: 20px;
  }
`

const MobileImage = styled.div`
  display: none;

  @media screen and (max-width: 1200px) {
    display: block;
  }

  img {
    display: block;
    max-height: 60vh;
    margin: 0 auto;
    max-width: 100%;
  }

  p {
    font-style: italic;
    text-align: center;
  }
`

const Box = styled(DefaultBox)`
  p b {
    color: #284d71;
  }
  
  i {
    color: #284d71;
    font-weight: bold;
  }
`

const HighlightedDose = styled.p`
    color : #95ba83;
    font-size: 20px !important;
    font-family: OpenSans;
    font-weight : bold;
    line-height: 2.06;
`

const useBodyPartDetails = () => {
    const {part} = useParams()
    return bodyPartsDefs[part]
}




const Part = () => {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }


    function closeModal() {
        setIsOpen(false);
    }
    const history = useHistory();

    const def = useBodyPartDetails();
    if (!def) {
        history.replace("/");
        return;
    }
    const {
        img,
        title,
        caption,
        text,
        dose,
    } = def;



    return (
        <SideBarLayout>
            <Menu />
            <ColumnsContent>
                <Column>
                    <Box>
                        <MenuButton/>

                        <h1>{title}</h1>

                        <MobileImage>
                            <img onClick={openModal} src={img} alt=""/>
                            <p>{caption}</p>
                        </MobileImage>

                        <p dangerouslySetInnerHTML={{__html: text}}/>
                        <i>{dose}</i>
                    </Box>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                    >
                        <img src={img} />
                    </Modal>

                </Column>
                <DesktopImage>
                    <img onClick={openModal} src={img} alt="" />
                    <p>{caption}</p>
                    <BackButton />
                </DesktopImage>
            </ColumnsContent>
        </SideBarLayout>
    );
};

export default Part;
