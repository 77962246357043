import {format as formatDate} from "date-fns";
import html2pdf from "html2pdf.js";
import React, {useRef, useState} from "react";
import {Calendar} from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
import doneIconUrl from "../assets/images/done.svg";
import {Box} from "../components/Box";
import exportIconSrc from "../assets/images/save.svg"
import removeIconUrl from "../assets/images/x-mark.svg";
import {BackButton, WhiteButton} from "../components/Buttons";
import {Dropdown} from "../components/Dropdown";
import {CenteredContent, SideBarLayout, SingleColumnLayout} from "../components/layouts";
import logo from '../assets/images/logo.svg'
import Menu, {MenuButton} from "../components/Menu";
import { useLocalXRayHistory} from "../lib/history";

const format = (d) => formatDate(new Date(d), "yyyy-MM-dd");

const MenuBar = styled.div`
  position: relative;
  margin: 0 0 40px 0;
  display: none;
  font-family: "Calibri-Regular", sans-serif;

  height: 2em;

  @media screen and (max-width: 768px) {
    display: block;
  }
`

const Table = styled.table`
  width: 100%;
  font-family: "Calibri-Regular", sans-serif;
  border-spacing: 0;
  margin-bottom: 30px;

  td, th {
    @media screen and (max-width: 768px) {
      padding: 10px 5px;
      font-size: 0.8em;
    }
    
    @media screen and (min-width: 769px) {
      padding: 20px 10px;
    }
  }

  th {
    border-bottom: 2px solid black;
  }

  td {
    border-bottom: 1px solid black;
  }

  .ctrl {

    @media screen and (max-width: 768px) {
      padding: 10px 0 10px 10px;
    }

    @media screen and (min-width: 769px) {
      padding: 20px 0 20px 20px;
    }
    
    border-bottom: none;
    width: 1px;
    transform: translateY(2px);

    img {
      cursor: pointer;
    }
  }

  tr > *:nth-child(2) {
    text-align: left;
    background-color: rgba(136, 185, 207, 0.3);
    min-width: 200px;
    width: 8px;
  }

  tr > *:nth-child(3) {
    text-align: left;
  }
`;

const ValueRow = (props) => {
    const {entry: e, onDelete} = props;

    return (
        <tr>
            <td className="x-ray__table__value">{format(e.date)}</td>
            <td className="x-ray__table__value">{e.type}</td>
            <td className="x-ray__table__value">{e.dose}</td>
            {onDelete && (
                <td className="ctrl">
                    <img src={removeIconUrl} onClick={onDelete} alt=""/>
                </td>
            )}
        </tr>
    );
};

const InlineInput = styled.input`
  padding: 0;
  border: none;
  font-family: "Calibri-Regular", sans-serif;
  width: 100%;
  text-align: left;
  outline: none;
  @media screen and (max-width : 768px) {
  text-align:center;
  }
`;

const HighCenter = styled.div`
  @media screen and (min-width: 769px) {
    margin: -10px -20px;
    padding: 10px 20px;
    border-top: 1px solid black;
    border-bottom: 1px solid #595959;
    background: white;
  }
`

const HighLeft = styled(HighCenter)`
  @media screen and (min-width: 769px) {
    margin-left: -10px;
    padding-left: 10px;
    border-left: 1px solid #595959;
  }
`

const HighRight = styled(HighCenter)`
  @media screen and (min-width: 769px) {
    margin-right: -10px;
    padding-right: 10px;
    border-right: 1px solid #595959;
  }
`

const Control = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const PrintView = styled.div`
  height: 1px;
  overflow: hidden;
`;

const Logo = styled.img`
    width: 25%;
    margin-top : 8px;
    margin-bottom : 40px;
    @media screen and (max-width: 900px) {
    width:38%;
     }
    @media screen and (max-width: 600px) {
    width:43%;
     }
      @media screen and (max-width: 400px) {
    width:50%;
     }
`

const ThDesktop = styled.th`
    display : block;
    font-family: "Calibri-Regular", sans-serif;
    @media screen and (max-width : 500px) {
    display : none;
    }
`

const ThMobile = styled.th`
    display : none;
    font-family: "Calibri-Regular", sans-serif;
     @media screen and (max-width : 500px) {
    display : block;
    text-align: center !important;
}
`


const TableView = (props) => {
    const {
        history,
        handleDelete,
        newDate,
        newType,
        newDose,
        setNewDate,
        setNewType,
        setNewDose,
        handleAdd,
    } = props;

    return (
        <>
            <Table>
                <thead>
                <tr>
                    <th className="x-ray__table__header">Datum</th>
                    <ThDesktop>Art der Anwendung und untersuchte Körperregion</ThDesktop>
                    <ThMobile>Art der Anwendung und untersuchte Körperregion</ThMobile>
                    <th className="x-ray__table__header">Arzt</th>
                    {(handleAdd || handleDelete) && (
                        <th className="ctrl"/>
                    )}
                </tr>
                </thead>
                <tbody>
                {history.map((h, i) => (
                    <ValueRow key={i} entry={h} onDelete={handleDelete && handleDelete(i)}/>
                ))}

                {handleAdd && (
                    <InputRow
                        date={newDate}
                        type={newType}
                        dose={newDose}
                        onDateChange={setNewDate}
                        onTypeChange={setNewType}
                        onDoseChange={setNewDose}
                        onAdd={handleAdd}
                    />
                )}
                </tbody>
            </Table>
        </>

    )
}

const TableDescription = styled.p`
  font-family: "Calibri-Regular", sans-serif;
`

const InputRow = (props) => {
    const {date, type, dose, onDateChange, onTypeChange, onDoseChange, onAdd} =
        props;

    const handleSubmit = e => {
        e.preventDefault();
        onAdd();
    }

    return (
        <tr>
            <td>
                <HighLeft>
                    <Dropdown text={date ? format(date) : "Datum"} value={date}>
                        <Calendar value={date} onChange={onDateChange}/>
                    </Dropdown>
                </HighLeft>
            </td>
            <td>
                <HighCenter>
                    <InlineInput
                        placeholder="Körperteil"
                        value={type || ""}
                        onChange={(e) => onTypeChange(e.target.value)}
                    />
                </HighCenter>
            </td>
            <td>
                <HighRight>
                    <form onSubmit={handleSubmit}>
                        <InlineInput
                            placeholder="Arzt"
                            value={dose || ""}
                            onChange={(e) => onDoseChange(e.target.value)}
                        />
                    </form>
                </HighRight>
            </td>
            <td className="ctrl">
                <img src={doneIconUrl} onClick={onAdd} alt=""/>
            </td>
        </tr>

    );
};

const XRayHistory = () => {
    const tableRef = useRef();

    const [newDate, setNewDate] = useState();
    const [newType, setNewType] = useState();
    const [newDose, setNewDose] = useState();

    const {history, updateHistory} = useLocalXRayHistory();

    const handleDelete = (index) => {
        return () => updateHistory(history.filter((h, i) => i !== index));
    };

    const handleAdd = () => {
        if (!newDate || !newType || !newDose) {
            return;
        }

        updateHistory([
            ...history,
            {
                date: newDate,
                type: newType,
                dose: newDose,
            },
        ]);

        setNewDate(undefined);
        setNewType(undefined);
        setNewDose(undefined);
    };

    const handleExportPDF = () => {
        const dateStr = formatDate(new Date(), "yyyy-MM-dd");

        html2pdf()
            .set({
                margin: 0.4,
                filename: `Röntgenhistorie_${dateStr}.pdf`,
                // html2canvas: {scale: 2},
                jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
            })
            .from(tableRef.current)
            .toPdf()
            .save()
    }

    return (
        <SideBarLayout>
            <Menu/>

            <CenteredContent>
                <MenuBar>
                    <MenuButton/>
                </MenuBar>
                <Logo src={logo}/>
                <TableView
                    history={history}
                    handleDelete={handleDelete}
                    newDate={newDate}
                    newType={newType}
                    newDose={newDose}
                    setNewDate={setNewDate}
                    setNewType={setNewType}
                    setNewDose={setNewDose}
                    handleAdd={handleAdd}
                />


                <PrintView>
                    <br/>
                    <br/>
                    <div ref={tableRef}>
                        <div>
                            <Logo src={logo}/>
                        </div>
                        <b style={{fontSize: '20px', float: 'left',  fontFamily : "Calibri-Regular , , sans-serif"}}>Röntgenpass</b>

                        <TableView history={history}/>
                        <TableDescription>
                            Ein Röntgenpass kann Ihre Ärztin oder Ihren Arzt über Ihre früheren Röntgen- und nuklearmedizinischen Untersuchungen informieren.
                            Tragen Sie dazu Röntgen- und nuklearmedizinische Untersuchungen möglichst vollständig ein und klicken Sie abschließend auf den grünen Haken.
                            Sie können Untersuchungen jederzeit ergänzen, exportieren oder löschen.
                        </TableDescription>
                    </div>
                </PrintView>

                <Control>
                    <BackButton/>
                    <WhiteButton
                        onClick={handleExportPDF}
                    >
                        <img src={exportIconSrc} alt=""/>
                        Export
                    </WhiteButton>
                </Control>
                <SingleColumnLayout margin={'20px 20px 0 0'}>
                    <Box>
                        <TableDescription>
                            Ein Röntgenpass kann Ihre Ärztin oder Ihren Arzt über Ihre früheren Röntgen- und nuklearmedizinischen Untersuchungen informieren.
                            Tragen Sie dazu Röntgen- und nuklearmedizinische Untersuchungen möglichst vollständig ein und klicken Sie abschließend auf den grünen Haken.
                            Sie können Untersuchungen jederzeit ergänzen, exportieren oder löschen.
                        </TableDescription>
                    </Box>
                </SingleColumnLayout>
            </CenteredContent>
        </SideBarLayout>

    );
};

export default XRayHistory;
