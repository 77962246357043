import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import roentgenMobileSrc from "../assets/images/roentgen-mobile.svg"

import roentgenSrc from "../assets/images/roentgen.svg"
import Box from "../components/Box";
import {ForwardButton as _ForwardButton} from "../components/Buttons";
import {Column, ColumnsContent, SideBarLayout} from "../components/layouts";
import Menu, {MenuButton} from "../components/Menu";

const SpecialColumn = styled(Column)`
  text-align: center;
`

const DesktopImg = styled.img`
  @media screen and (max-width: 1200px) {
    display: none;
  }
`

const MobileImg = styled.img`
  @media screen and (min-width: 1201px) {
    display: none;
  }

  margin: 0 -20px;

  width: 100%;
`

const ForwardButton = styled(_ForwardButton)`
  @media screen and (max-width: 1200px) {

    width: 100%;
  }
`

const Link = styled.a`
    text-decoration : none;
    color : black;
    font-weight : 900;
`

const Start = () => {

    const history = useHistory();

    const handleGoAhead = () => {
        history.push("/home")
    }

    return (
        <SideBarLayout>
            <Menu/>


            <ColumnsContent>
                <SpecialColumn>
                    <DesktopImg src={roentgenSrc} alt=""/>
                </SpecialColumn>

                <Column>
                    <Box>
                        <MenuButton/>

                        <h1>Diagnostische Strahlenanwendungen: Bilder aus dem Inneren des Körpers</h1>

                        <MobileImg src={roentgenMobileSrc} alt=""/>

                        <p>
                            Bildgebende Verfahren wie Röntgenaufnahmen, Röntgendurchleuchtungen und Computertomographien (CT) sind aus der Medizin nicht wegzudenken.
                            Welche Dosis bei welcher Art von Untersuchung auf den Körper wirkt, und was Patient*innen aus Sicht des Strahlenschutzes dazu wissen sollten, das erfahren Sie in dieser <Link href='/home'>Grafik</Link>.
                            Weitere Informationen wie einen <Link href={'/dose-table'}>Überblick zur Strahlendosis</Link> oder ein <Link href={'/x-ray-history'}>Formular zur Dokumentation von Röntgenaufnahmen</Link> (Röntgenpass) finden Sie im Menü.
                        </p>
                    </Box>

                    <ForwardButton onClick={handleGoAhead}/>
                </Column>
            </ColumnsContent>
        </SideBarLayout>
    );
};

export default Start;
