import React, {useState} from "react";
import "react-calendar/dist/Calendar.css";
import Box from "../components/Box";
import {SingleColumnLayout, SideBarLayout} from "../components/layouts";
import Menu, {MenuButton} from "../components/Menu";
import styled from "styled-components";

const Link = styled.a`
    text-decoration : none;
    color : black;
    font-weight : 900;
`

const Tips = () => {
    return (
        <SideBarLayout>
            <Menu/>
            <SingleColumnLayout>
                <Box>
                <MenuButton/>
                    <h1>So halten Sie Ihre Strahlenexposition niedrig</h1>
                    <p><li>Sprechen Sie Ihre Ärztin oder Ihren Arzt gezielt auf alternative Diagnoseverfahren an (zum Beispiel eine Magnetresonanztomografie oder eine Ultraschalluntersuchung).</li></p>
                    <p><li>Sagen Sie Ihrer Ärztin oder Ihrem Arzt, ob bei Ihnen in letzter Zeit ähnliche Untersuchungen durchgeführt wurden.</li></p>
                    <p><li>Bringen Sie bereits vorhandene Aufnahmen von röntgenologischen oder nuklearmedizinischen Untersuchungen und die entsprechenden Befunde mit oder lassen Sie diese anfordern.</li></p>
                    <p><li>Frauen sollten vor einer Strahlenanwendung sagen, ob eine Schwangerschaft besteht oder für möglich gehalten wird.</li></p>
                    <p><li>Führen Sie ein Dokument, in das Sie <Link href={'/x-ray-history'}>Röntgen</Link>- und nuklearmedizinische Untersuchungen eintragen lassen – etwa einen <Link href={'/x-ray-history'}>Röntgenpass oder eine digitale Röntgenhistorie</Link>.</li></p>
                </Box>
            </SingleColumnLayout>
        </SideBarLayout>
    )
};

export default Tips;
